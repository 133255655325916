import React, { useState } from "react"

const ProductComposition = ({
  color,
  inside,
}) => {
  return (
    <div className={"product-composition"}>
      <h3
        className={"product-composition__title"}
        style={{ borderColor: color }}
      >
        {inside.title}
      </h3>
      <div className="product-composition__other" dangerouslySetInnerHTML={{ __html: inside.text || "" }}  />
    </div>
  )
}

export default ProductComposition
