import { Swiper, SwiperSlide } from "swiper/react"
import Img from "../../../helpers/img"
import { Link } from "gatsby"
import { ROUTES } from "../../../../constants.js"
import React from "react"

const ProductOthers = ({
  title = "",
  sameRangeProducts = [],
  color,
  id,
  isMobile,
  type,
  name,
}) => {
  const filteredProducts = sameRangeProducts.filter(
    product => {
      if(type == 'pack'){
        let isGood = type === product.product_content.productType;
        return product.Product.productId !== id && isGood
      } else {
        let isGood = type === product.product_content.productType;
        let isSameProduct = name === product.Product.productName;
        // console.log({sameRangeProducts}, type, product.product_content.productType, name, product.Product.productName);
        return product.Product.productId !== id && isGood && isSameProduct;
      }

    }
  )
  return (
    <div className={"product-others"}>
      <div className={"product-others__head"} style={{ borderColor: color }}>
        <h3 className={"title"}>{title}</h3>
      </div>
      <div className={"product-list"}>
        {isMobile ? (
          <Swiper
            slidesPerView={1.5}
            spaceBetween={0}
            breakpoints={{
              1024: {
                slidesPerView: 4,
                spaceBetweenSlides: 0,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetweenSlides: 0,
              },
            }}
          >
            {!!filteredProducts &&
              filteredProducts.map(
                (
                  {
                    slug,
                    Product: { productColor, productImage, variantName },
                  },
                  i
                ) => (
                  <SwiperSlide key={i} className="product-list__card">
                    <ProductCardInner
                      link={`${ROUTES.SHOP}/${slug}`}
                      productColor={productColor}
                      productImage={productImage}
                      variantName={variantName}
                    />
                  </SwiperSlide>
                )
              )}
          </Swiper>
        ) : (
          <>
            {!!filteredProducts &&
              filteredProducts.map(
                (
                  {
                    slug,
                    Product: { productColor, productImage, variantName },
                  },
                  i
                ) => (
                  <div key={i} className="product-list__card">
                    <ProductCardInner
                      link={`${ROUTES.SHOP}/${slug}`}
                      productColor={productColor}
                      productImage={productImage}
                      variantName={variantName}
                    />
                  </div>
                )
              )}
          </>
        )}
      </div>
    </div>
  )
}

export default ProductOthers

const ProductCardInner = ({
  link,
  productColor,
  productImage,
  variantName,
}) => (
  <Link to={link}>
    <div
      className="product-list__card__imageWrapper"
      style={{ backgroundColor: productColor }}
    >
        {!!productImage && !!productImage.sourceUrl && 
          <Img source={productImage.sourceUrl} alt={productImage?.altText ? productImage?.altText : "Funky Veggie - Produit - " + (variantName || ' ')} classe='product-list__card__image' />
        }
    </div>
    <p className="product-list__card__variantName">{variantName}</p>
  </Link>
)
