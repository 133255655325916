import { DEFAULT_PACK_COLOR, PRODUCT_TYPES } from "../../constants.js"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"

import FeaturedProducts from "../components/featured-products/featured-products.js"
import Layout from "../components/layout/layout.js"
import ProductComposition from "../components/shop/product-composition/product-composition.js"
import ProductCompositionOther from "../components/shop/product-composition/product-composition-other.js"
import ProductDescription from "../components/shop/product-description/product-description.js"
import ProductHead from "../components/shop/product-head/product-head.js"
import ProductIdea from "../components/shop/product-idea/product-idea.js"
import ProductInsideBag from "../components/shop/product-inside-bag/product-inside-bag.js"
import ProductOthers from "../components/shop/product-others/product-others.js"
import ProductRecipe from "../components/shop/product-recipe/product-recipe.js"
import ProductSpec from "../components/shop/product-spec/product-spec.js"
import SEO from "../components/seo.js"
import { graphql } from "gatsby"
import useDocumentScrollThrottled from "../helpers/hooks/useDocumentScrollThrottled.js"
import useWindowSize from "../helpers/hooks/useWindowSize.js"

const ProductPage = ({
  location,
  pageContext,
  data: { sameRangeProducts },
}) => {
  const {
    color,
    product_content: {
      description,
      descriptionOther,
      productType,
      specifications,
      composition,
      compositionFull,
      inside,
      insideBag,
      blocks,
      recipes,
    },
    seo,
  } = pageContext

  // Hooks
  const sizes = useWindowSize()
  const mainRef = useRef()
  const [isSimpleProduct, setIsSimpleProduct] = useState(
    productType === PRODUCT_TYPES.SIMPLE
  )
  const [isOtherProduct, setIsOtherProduct] = useState(
    productType === PRODUCT_TYPES.GOODIES
  )

  useEffect(() => {
    setIsSimpleProduct(productType === PRODUCT_TYPES.SIMPLE)
    setIsOtherProduct(productType === PRODUCT_TYPES.GOODIES)
  }, [productType])

  // Animation
  const headHeight = 500
  const headRotationMax = 32
  const speedCoef = 3 // Si 1 : scroll lineair de haut en bas du bloc / Si 2: se decouvre 2 fois plus vite
  const [productTransform, setProductTransform] = useState({
    rotation: 0,
    scale: 1,
  })

  useDocumentScrollThrottled(({ currentScrollTop }) => {
    if (!!pageContext.imageAnimation) {
      let norm = () =>
        Math.min(
          Math.max(
            (currentScrollTop * speedCoef +
              headHeight -
              mainRef.current.getBoundingClientRect().height) /
              (mainRef.current.getBoundingClientRect().height -
                headHeight -
                mainRef.current.offsetTop) +
              1,
            0
          ),
          1
        )
      const scale = norm() / 3 + 1
      const rotation = norm(true) * -headRotationMax
      setProductTransform({ rotation, scale })
    }
  })

  return (
    <Layout location={location}>
      {/* <SEO title={seo.title} description={seo.metaDesc} type='product' /> */}
      <SEO
        meta={seo}
        type="product"
        image={(pageContext?.image?.sourceUrl || "").replace(
          "https://funky.buddybuddy.io/wp-content/uploads/",
          "https://cdn.funkyveggie.fr/wp-content/uploads/"
        )}
      />
      <Helmet>
        {/* <meta property="og:title" content={JSON.stringify(pageContext.name +' - '+ pageContext.variantName)} /> */}
        {/* <meta property="og:type" content="product" /> */}
        <meta
          property="product:price:amount"
          content={pageContext.shopifyVariants[0].price}
        />
        <meta property="product:price:currency" content="EUR" />

        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org/",
              "@type": "Product",
              "name": ${JSON.stringify(
                pageContext?.name + " - " + pageContext?.variantName
              )},
              "image": "${(pageContext?.image?.sourceUrl || "").replace(
                "https://funky.buddybuddy.io/wp-content/uploads/",
                "https://cdn.funkyveggie.fr/wp-content/uploads/"
              )}",
              "description": ${JSON.stringify(
                pageContext?.product_content?.description
              )},
              "sku": "${pageContext?.shopifyVariants?.[0]?.sku}",
              "offers": {
                "@type": "Offer",
                "priceCurrency": "EUR",
                "price": "${pageContext?.shopifyVariants?.[0]?.price}",
                "availability": "http://schema.org/InStock",
                "seller": {
                  "@type": "Organization",
                  "name": "funkyveggie.fr"  
                }
              } 
            }        
          `}
        </script>
      </Helmet>
      <div className={"container allow-overflow"}>
        <div className={"page-product"}>
          {!sizes.isMobile && (
            <section
              className={"page-product__aside"}
              style={{ backgroundColor: color }}
            >
              <div
                className={"page-product__background"}
                style={{ backgroundColor: color }}
              />
              <ProductHead
                {...pageContext}
                productRotation={productTransform.rotation}
                productScale={productTransform.scale}
                headHeight={headHeight}
              />
            </section>
          )}
          <section className={"page-product__main"} ref={mainRef}>
            {sizes.isMobile && <ProductHead {...pageContext} />}
            {/* {!isOtherProduct && ()} */}
            <ProductDescription
              {...pageContext}
              description={!isOtherProduct ? description : descriptionOther}
              color={isSimpleProduct ? color : DEFAULT_PACK_COLOR}
              isSimpleProduct={isSimpleProduct}
              isOther={isOtherProduct}
            />
            {productType === PRODUCT_TYPES.PACK && (
              <ProductInsideBag {...insideBag} />
            )}
            <ProductSpec
              specs={specifications}
              color={isSimpleProduct ? color : DEFAULT_PACK_COLOR}
            />
            {!!sameRangeProducts &&
              !!sameRangeProducts.nodes &&
              !isOtherProduct && (
                <ProductOthers
                  {...pageContext}
                  color={isSimpleProduct ? color : DEFAULT_PACK_COLOR}
                  type={productType}
                  title={
                    productType === PRODUCT_TYPES.PACK
                      ? "Autres packs"
                      : "Autres goûts"
                  }
                  isMobile={sizes.isMobile}
                  sameRangeProducts={sameRangeProducts.nodes}
                />
              )}
            {isSimpleProduct && (
              <ProductComposition
                inside={inside}
                composition={composition}
                compositionFull={compositionFull}
                color={isSimpleProduct ? color : DEFAULT_PACK_COLOR}
              />
            )}

            {productType === "other" && !!inside.text && (
              <ProductCompositionOther
                inside={inside}
                color={DEFAULT_PACK_COLOR}
              />
            )}
          </section>
        </div>

        <section className={"page-product__full"}>
          <ProductIdea content={blocks} />
          <ProductRecipe content={recipes} />
          <FeaturedProducts withSingleTitle={true} />
        </section>
      </div>
    </Layout>
  )
}

export default ProductPage

export const query = graphql`
  query SameRangeProducts($rangeSlug: [String]!) {
    sameRangeProducts: allWpProduct(
      filter: {
        product_ranges: { nodes: { elemMatch: { slug: { in: $rangeSlug } } } }
      }
    ) {
      nodes {
        slug
        uri
        title
        product_content {
          productType
        }
        Product {
          productColor
          productName
          productId
          variantName
          productImage {
            sourceUrl
            altText
          }
        }
      }
    }
  }
`
