import React, { useContext, useEffect, useState } from "react"

import StoreContext from "~/context/StoreContext"
import useWindowSize from "../../../helpers/hooks/useWindowSize.js"
import useDebounce from "../../../helpers/hooks/useDebounce.js"
import { UNITS } from "../../../../constants.js"
import { head } from "lodash/fp"

const getHumanUnit = (shoppifyWeightUnit = "") => {
  if (shoppifyWeightUnit === UNITS.KG) return "kg"
  if (shoppifyWeightUnit === UNITS.G) return "g"
  return (
    shoppifyWeightUnit.charAt(0).toUpperCase() +
    shoppifyWeightUnit.slice(1).toLowerCase()
  )
}

const ProductDescription = ({
  name,
  variantName,
  color,
  shopifyVariants,
  shopifyAvailableForSale,
  shopifyProductId,
  description,
  isOther,
  isSimpleProduct,
}) => {
  // Context
  const {
    addVariantToCart,
    setIsCartOpen,
    updateLineItem,
    isProductAvailable,
    store: { client, checkout },
  } = useContext(StoreContext)

  const getItemToUpdate = () => {
    let theItem = checkout.lineItems.filter(
      item => item.variant.id === shopifyVariants[0].shopifyId
    )
    return head(theItem)
  }

  // Hooks
  const { isMobile } = useWindowSize()

  // State
  const [itemToUpdate, setItemToUpdate] = useState(getItemToUpdate())
  const [isAvailable, setIsAvailable] = useState(false)
  const [tempQty, setTempQty] = useState(1)

  // Handlers
  const handleAddQty = () => {
    setTempQty(tempQty < 99 ? tempQty + 1 : 99)
  }
  const handleRemQty = () => {
    setTempQty(tempQty > 1 ? tempQty - 1 : 1)
  }

  const addProductToCart = tempQty => {
    if (
      checkout?.id &&
      itemToUpdate?.id &&
      client &&
      tempQty &&
      getItemToUpdate()
    ) {
      updateLineItem(client, checkout.id, itemToUpdate.id, tempQty)
    } else if (shopifyVariants[0].shopifyId && tempQty) {
      addVariantToCart(shopifyVariants[0].shopifyId, tempQty)
    }
    setIsCartOpen(true)
    setTempQty(1)
  }

  // Lifecycle
  useEffect(() => {
    async function checkAvailable() {
      // console.log('test => ',shopifyProductId);
      // console.log('is available => ', await isProductAvailable(shopifyProductId));
      setIsAvailable(await isProductAvailable(shopifyProductId))
    }
    checkAvailable()
  }, [])

  useEffect(() => {
    setItemToUpdate(getItemToUpdate())
  }, [checkout.lineItems, shopifyVariants])

  return (
    <div className={"product-description"}>
      <h1 className={"product-description__title"}>{name}</h1>
      <h2 className={"product-description__subTitle"} style={{ color: color }}>
        {variantName}
      </h2>
      <div
        className={"product-description__info " + (isOther ? "other" : "")}
        dangerouslySetInnerHTML={{ __html: description || "Description" }}
      />
      {!!shopifyVariants && !!isSimpleProduct && (
        <span className={"product-description__weight"}>
          Poids unitaire: {shopifyVariants[0].weight}
          {getHumanUnit(shopifyVariants[0].weightUnit)}
        </span>
      )}
      <div className={"product-description__shopping"}>
        <div className={"product-description__qty"}>
          <button
            style={{ color: color }}
            disabled={!isAvailable}
            onClick={() => handleRemQty()}
          >
            -
          </button>
          {/* <span
            style={{
              backgroundColor: color,
            }}
          >
            {tempQty}
          </span> */}
          <input
            style={{
              backgroundColor: color,
            }}
            type="text"
            value={tempQty}
            maxLength={2}
            pattern={"[0-9]+"}
            onChange={e => {
              let currVal = e.target.value || ""
              currVal = currVal
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*)\./g, "$1")
              setTempQty(parseInt(currVal) || 0)
            }}
            onKeyPress={e => {
              if (e.key === "Enter") {
                let currVal = e.target.value || ""
                currVal = currVal
                  .replace(/[^0-9.]/g, "")
                  .replace(/(\..*)\./g, "$1")
                addProductToCart(parseInt(currVal) || 0)
              }
            }}
          />
          <button
            style={{ color: color }}
            disabled={!isAvailable}
            onClick={() => handleAddQty()}
          >
            +
          </button>
        </div>
        {!!shopifyVariants && (
          <button
            className={"product-description__addToCart"}
            style={{
              backgroundColor: color,
            }}
            disabled={!isAvailable}
            onClick={() => addProductToCart(tempQty)}
          >
            {isAvailable ? (
              <>
                Ajouter au panier - {`${shopifyVariants[0].price}€`}
                {shopifyVariants?.[0]?.compareAtPrice ? (
                  <span className="oldPrice">
                    {`${shopifyVariants[0].compareAtPrice}€`}
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              `Produit indisponible`
            )}
          </button>
        )}
      </div>
    </div>
  )
}

export default ProductDescription
