import React, { useState } from "react"

const ProductComposition = ({
  color,
  inside: { title = "", items = [] },
  composition,
  compositionFull,
}) => {
  const [isCompOpen, setIsCompOpen] = useState(false)
  // console.log(compositionFull);
  return (
    <div className={"product-composition"}>
      <h3
        className={"product-composition__title"}
        style={{ borderColor: color }}
      >
        {title}
      </h3>
      {!!items && (
        <ul className={"product-composition__items"}>
          {items &&
            items.map(({ icon = {}, subtitle = "", title = "" }, i) => {
              return (
                <li className={"product-composition__item"} key={i}>
                  {!!icon && !!icon.localFile && !!icon.localFile.publicURL && (
                    <div className={"product-composition__icon"}>
                      <img src={icon.localFile.publicURL} alt="" />
                    </div>
                  )}

                  <div className={"product-composition__text"}>
                    {title}
                    <span>{subtitle}</span>
                  </div>
                </li>
              )
            })}
        </ul>
      )}
      <div
        className={"product-composition__expand"}
        style={{ borderColor: color }}
      >
        <button
          onClick={() => setIsCompOpen(!isCompOpen)}
          style={{ color: color }}
        >
          Composition{" "}
          <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
            <path
              d="M12 5v14M5 12h14"
              stroke={color}
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {!!compositionFull.ingredients && 
            <div className={`product-composition__expand__content${
              isCompOpen ? " open" : ""
            }`}> 
              <h2>Ingrédients</h2>
              <div dangerouslySetInnerHTML={{ __html: compositionFull.ingredients || "Composition" }} />
              
              <h2>Valeurs nutritionnelles :</h2>
              <div className="product-composition-values">
                <div className="line">
                    <div className={`labels`}></div>
                    <div className="smallVal bold">{compositionFull.valeursNutrionnelles.firstG}</div>
                    <div className="bigVal bold">{compositionFull.valeursNutrionnelles.secondG}</div>
                </div>
                {compositionFull.valeursNutrionnelles.tab.map((item) => (
                  <div className="line">
                    <div className={`labels ${item.bold ? 'bold' : ''}`}>{item.label}</div>
                    <div className="smallVal">{item.first}</div>
                    <div className="bigVal">{item.second}</div>
                  </div>
                ))}

                <h2>Autres informations</h2>
                <div dangerouslySetInnerHTML={{ __html: compositionFull.others || "Composition" }} />

              </div>
            </div>
          } 
      </div>
    </div>
  )
}

export default ProductComposition
