import React from "react"

const ProductSpec = ({ color, specs }) => {
  return (
    <div className={"product-spec"}>
      <ul className={"product-spec__items"} style={{ borderColor: color }}>
        {specs &&
          specs.map(({ icon, number, type, texte }, i) => {
            return (
              <li className={"product-spec__item"} key={i}>
                <div className={"product-spec__icon"}>
                  <Ellipse color={color} />
                  {type === "number" || type === null ? (
                    <span className={"product-spec__score"}>
                      {number ? number : 0}
                    </span>
                  ) : !!icon &&
                    !!icon?.localFile &&
                    !!icon?.localFile?.publicURL ? (
                    <img src={icon?.localFile?.publicURL} alt={icon?.altText} />
                  ) : (
                    <svg
                      height="800px"
                      width="800px"
                      class="product-spec__checkmark"
                      version="1.1"
                      viewBox="0 0 32 32"
                    >
                      <g>
                        <g id="check">
                          <g>
                            <polygon
                              style={{fill: "#FFFFFF"}}
                              points="11.941,28.877 0,16.935 5.695,11.24 11.941,17.486 26.305,3.123 32,8.818"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  )}
                </div>
                <div className={"product-spec__text"}>{texte}</div>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

const Ellipse = ({ color }) => {
  return (
    <svg
      width={65}
      height={61}
      viewBox="0 0 65 61"
      preserveAspectRatio="none"
      fill="none"
    >
      <path
        d="M65 31c0 8.645-5.428 16.242-11 22-5.818 6.013-12.472 7.5-21.5 7.5-9.873 0-18.202-2.471-24.072-9.5C3.79 45.448 0 38.8 0 31c0-8.645 2.856-17.242 8.428-23C14.246 1.987 23.472 0 32.5 0 41.528 0 51.182 1.987 57 8c5.572 5.758 8 14.355 8 23z"
        fill={color}
      />
    </svg>
  )
}

export default ProductSpec
