import Lottie from "react-lottie"
import { PRODUCT_TYPES } from "../../../../constants.js"
import ProductAnimation from "../../../../static/animation/product-animation.json"
import React, {useState, useEffect} from "react"
import useWindowSize from "../../../helpers/hooks/useWindowSize.js"
import Img from '../../../helpers/img'

const ProductHead = ({
  image,
  imageAnimation,
  color,
  productRotation,
  productScale,
  headHeight,
  product_content: { productType, variantName, productName },
}) => {
  const { isMobile } = useWindowSize()
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: ProductAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const [showAnimationImg, setShowAnimationImg] = useState(false);

  useEffect(() => {
    if(!showAnimationImg){
      setTimeout(() => {
        setShowAnimationImg(true);
      }, 500);
    }
  }, [])

  return (
    <div className={"product-head"}>
      <div
        className={"page-product__background"}
        style={{ backgroundColor: color }}
      />
      {!isMobile && !!imageAnimation && (
        <>
          <div className="product-head__animation">
            <Lottie
              isStopped={productRotation >= 0}
              options={defaultOptions}
              height={500}
              width={300}
            />
          </div>
          {!!imageAnimation && !!imageAnimation.sourceUrl && showAnimationImg && (
            <div className="product-head__hidden" style={{
              transform: `scale(${productScale})`,
            }}>
              <Img 
                source={imageAnimation.sourceUrl} width={600} alt={image?.altText ? image.altText : "Zoom produit Funky Veggie - " + (variantName || '') + " " + (productName || '')}
              />
            </div>
          )}
        </>
      )}

      <div 
        className='image'
        style={{
          transform: `rotate(${productRotation}deg) translate3d(${productRotation}px, 0, 0)`,
          height: `${headHeight}px`,
        }} 
      >
        {!!image && !!image.sourceUrl && (
        <Img 
          source={image.sourceUrl} alt={image?.altText ? image.altText : "Funky Veggie - " + (variantName || '') + " " + (productName || '')} width={700}
        />)}
      </div>

    </div>
  )
}

export default ProductHead
