import { Link } from "gatsby"
import React from "react"
import groupBy from "lodash/groupBy"
import Img from "../../../helpers/img"

// Pouvait boguer ici si contributeur oubli la gamme en contribution dans WP, ou si produit dans la liste est en draft
const getRange = product => product.product_ranges.nodes[0].name

const removeItemsWithEmptyProductRanges = (arr) => {
  return arr.filter(item => {
    return item.hasOwnProperty('product_ranges');
  });
}

// FIx: On filtre d'abord les produits de la liste qui n'ont pas de clef product_ranges, c'est le cas des produits en draft dans WP et associés a ce pack
const groupProductsByRange = products => {
  let cleanProductList = removeItemsWithEmptyProductRanges(products)
  return groupBy(cleanProductList, getRange)
}

const getNumberProducts = products => {
  let number = 0
  for (let product of products) {
    number = number + product.number
  }
  return number
}

const ProductInsideBag = ({ allproducts, products, title }) => {
  let lastProducts = []

  for (let product of allproducts) {
    let line = { ...product.product, number: product.number }
    lastProducts.push(line)
  }

  const groupedProducts = groupProductsByRange(lastProducts)

  return (
    <div className={"product-inside-bag"}>
      {!!groupedProducts && (
        <div className={"product-items"}>
          <h3 className={"title"}>{title}</h3>
          {Object.keys(groupedProducts).map((key, i) => {
            return (
              <div className="product-group" key={i}>
                <div className="head">
                  <p className="number">
                    <sub>x</sub>
                    {/* {groupedProducts[key].length} */}
                    {getNumberProducts(groupedProducts[key])}
                  </p>
                  <h4 className="group-title">{key}</h4>
                </div>
                {groupedProducts[key].map(
                  ({
                    number,
                    uri,
                    Product: {
                      productColor,
                      productId,
                      productImage,
                      variantName,
                    },
                  }) => (
                    <div key={productId} className="product-inside__card">
                      <Link to={uri}>
                        <div
                          className="product-inside__card__imageWrapper"
                          style={{ backgroundColor: productColor }}
                        >
                          {!!productImage &&
                            !!productImage.sourceUrl && (
                              <Img source={productImage.sourceUrl} alt={productImage?.altText ? productImage.altText : "Funky Veggie " + variantName} classe='product-inside__card__image' />
                          )}

                        </div>
                        <p className="product-inside__card__variantName">
                          <strong>x{number}</strong> {variantName}
                        </p>
                      </Link>
                    </div>
                  )
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ProductInsideBag
