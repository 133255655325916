import LampIcon from "~/images/titles/lamp.svg"
import React from "react"
import TitleIcon from "../../decoration/title-icon.js"

const ProductIdea = ({ content }) => {
  return (
    !!content &&
    content.map(({ icon, title, block }, i) => {
      return (
        <div className={"product-idea"} key={i}>
          {title && (
            <h2 className={"product-idea__title"}>
              Une idée de fou
              <TitleIcon
                color="#8347ad"
                icon={
                  <img
                    src={
                      !!icon && !!icon.localFile && !!icon.localFile.publicURL
                        ? icon.localFile.publicURL
                        : LampIcon
                    }
                    alt={!!icon && icon.altText ? icon.altText : 'idée'}
                  />
                }
              />
            </h2>
          )}
          {block &&
            block.map(({ text, title }, i) => {
              return (
                <div className={"product-idea__col"} key={i}>
                  {!!title && (
                    <h3 className={"product-idea__subtitle"}>{title}</h3>
                  )}
                  {!!text && <p className={"product-idea__text"}>{text}</p>}
                </div>
              )
            })}
        </div>
      )
    })
  )
}

export default ProductIdea
