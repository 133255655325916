import { Link } from "gatsby"
import Placeholder from "../../../images/image-placeholder.jpg"
import { ROUTES } from "../../../../constants.js"
import React from "react"
import StripeText from "../../decoration/stripe-text.js"
import parse from "html-react-parser"
import Lazyload from "react-lazyload"

import Img from '../../../helpers/img'

const ProductRecipe = ({ content: { description, title, recipes } }) => {
  const slicedRecipes =
    recipes && recipes.length > 2 ? recipes.slice(0, 2) : recipes
  return (
    !!slicedRecipes && (
      <div className={"product-recipe"}>
        <div className={"product-recipe__firstCol"}>
          {slicedRecipes &&
            slicedRecipes.map(({ featuredImage, id, uri, slug, title }, i) => {
              return (
                <div className={"product-recipe__card"}>
                  <Link to={`/recettes/${slug}`} title={"Voir toutes nos recettes"} key={i}>
                    <div className={"image"}>
                      {!!featuredImage &&
                      !!featuredImage.node &&
                      !!featuredImage.node.sourceUrl ? (
                        <Lazyload>
                          <Img source={featuredImage.node.sourceUrl} alt={featuredImage?.node?.altText ? featuredImage?.node?.altText : title} />
                        </Lazyload>
                      ) : (
                        <img src={Placeholder} alt="" />
                      )}
                    </div>
                    {title}
                  </Link>
                </div>
              )
            })}
          <Link
            className={"link link--black"}
            to={`${ROUTES.CATEGORY}/recettes`}
            title={"Voir toutes nos recettes"}
          >
            Voir toutes nos recettes
          </Link>
        </div>
        <div className={"product-recipe__secondCol"}>
          <h3 className={"product-recipe__title"}>
            {/* {parse(title, {
              replace: domNode => {
                if (domNode.name === "strong" || domNode.name === "span") {
                  return (
                    <StripeText
                      text={title.replace(/(<([^>]+)>)/gi, "")}
                      color={"#ff6b00"}
                    />
                  )
                }
              },
            })} */}
            {parse(title, {
              replace: domNode => {
                if (domNode.name === "strong" || domNode.name === "span") {
                  return (
                    <StripeText
                      text={domNode.children[0].data}
                      color={"#ff6b00"}
                    />
                  )
                }
              },
            })}
          </h3>
          <p className={"product-recipe__text"}>{description}</p>
        </div>
      </div>
    )
  )
}

export default ProductRecipe
